import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

interface GoogleJwtPayload {
  email: string;
  [key: string]: any;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleGoogleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
          credential: credentialResponse.credential,
        });

        if (response.data.message === 'Login successful' && response.data.token) {
          const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days
          localStorage.setItem('authToken', response.data.token); // Store JWT token
          localStorage.setItem('tokenExpiration', expirationTime.toString());

          const decodedToken = jwtDecode<GoogleJwtPayload>(credentialResponse.credential);
          const googleUsername = decodedToken.email;

          sha256(googleUsername)
            .then(hashedUsername => {
              localStorage.setItem('hashedUserID', hashedUsername);
            })
            .catch(error => {
              console.error('Error hashing username:', error);
            });


          setIsAuthenticated(true);
          navigate('/');
        }
      } catch (error) {
        console.error('Login failed:', error);
        setError('Login failed. Please try again.');
      }
    }
  };

  async function sha256(source: string) {
    const sourceBytes = new TextEncoder().encode(source);
    const digest = await crypto.subtle.digest("SHA-256", sourceBytes);
    return Array.from(new Uint8Array(digest))
      .map(x => x.toString(16).padStart(2, '0'))
      .join("");
  }

  const handleGoogleLoginFailure = () => {
    console.error('Google login failed');
    alert('Google login failed');
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <div>
        <h2>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={handleGoogleLoginFailure} />
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
